import axios, { AxiosError } from "axios";
import { useRouter } from "vue-router";

type UNAUTHORIZED_RESPONSE = {
  detail: string
}

const INTERCEPTOR_DISABLED_ROUTES = [
  // login api
  import.meta.env.VITE_APP_API_URL + 'account/login',

  // google login
  import.meta.env.VITE_APP_API_URL + 'account/google/login/callback'
]

export const useAxiosInterceptor = () => {
  const router = useRouter()
  let isDisableResponseInterceptor = false

  const requestInterceptor = axios.interceptors.request.use(function (config) {
    // check is request's endpoint in INTERCEPTOR_DISABLED_ROUTES or not
    if (config.url !== undefined && INTERCEPTOR_DISABLED_ROUTES.includes(config.url)) {
      return config
    }
    
    // Check is token defined in localstorage or not
    if(localStorage.getItem('token') !== null){
      // Bind Authorization header if it is not already set
      if(config.headers["Authorization"] === undefined) {
        config.headers["Authorization"] = `Token ${localStorage.getItem('token')}`
      }
    }
    return config;
  }, function (error) {
    // Do nothing
    return Promise.reject(error);
  });

  const responseInterceptor = axios.interceptors.response.use(
    function (response) {
      // No action required for status 200
      isDisableResponseInterceptor = true

      return response;
    }, async function (error) {
      // check is request's endpoint in INTERCEPTOR_DISABLED_ROUTES or not
      if (error.url !== undefined && INTERCEPTOR_DISABLED_ROUTES.includes(error.url)) {
        return Promise.reject(error);
      }
      // run only when not disable interceptor
      if(isDisableResponseInterceptor === false){
        isDisableResponseInterceptor = true
        // Handle 401
        if(
          (error as AxiosError).response !== undefined &&
          (error as AxiosError).response?.status === 401 &&
          (error as AxiosError).response?.data !== undefined &&
          ((error as AxiosError).response?.data as UNAUTHORIZED_RESPONSE).detail === "Invalid token."
        ) {
          try {
            await axios.post(import.meta.env.VITE_APP_API_URL + 'account/logout',{},
            {
              headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
              },
            });
            
          } catch (error) {
            console.error('Error logout user:', error);
          } finally {
              //Remove Token
              localStorage.removeItem('token')
        
              router.push('/auth/login');
          }
        }
      }

      return Promise.reject(error);
    }
  )

  return { requestInterceptor, responseInterceptor }
}