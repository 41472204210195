<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import { useAxiosInterceptor } from './utilities/axios_interceptor'

useAxiosInterceptor()
</script>

<template>
  <RouterView />
</template>

<style scoped>
</style>
